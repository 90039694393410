import React, { Fragment } from "react";
import Layout from "../../components/layout";
import ProductsNav from "../../components/navigation/ProductsNav";
import ProductsNavItem from "../../components/navigation/ProductsNavItem";

import VideoFirstIcon from "../../components/icon/icons/vf-icon-rgb.icon";
import VideoFirstCapture from "../../components/icon/icons/capture.icon";
import VideoFirstSlackIntegrationIcon from "../../components/icon/icons/vf-icon-slack-integration.icon";

import Icon from "../../components/icon";

import SlackAppTagsCropped from "../../components/integrations/slack/assignment-tags-cropped.png";
import SlackAppCommentsCropped from "../../components/integrations/slack/comment-cropped.png";
import SlackAppReviews from "../../components/integrations/slack/reviews.png";
import SlackConnectProject from "../../components/integrations/slack/slack-connect-project.png";
import SlackConnectChannel from "../../components/integrations/slack/slack-connect-channel.png";

import PrimaryButton from "../../components/button";
import GitHubIcon from "../../components/icon/icons/github.icon";
import AppFeatures from "../../components/appfeatures";
import Container from "../../components/container";
import ImagePlaceholder from "../../components/landing/vft-placeholder-video.png";

const CapturePage = props => (
  <Layout pageTitle="App - Products">
    <main>
      <ProductsNav>
      <ProductsNavItem
          text={"Video First App"}
          icon={VideoFirstIcon}
          link={"/products/app/"}
          active={false}/>
        {/* <ProductsNavItem
          text={"Video First Capture"}
          icon={VideoFirstCapture}
          link={"/products/capture/"}
          active={false}/> */}
        <ProductsNavItem
          text={"Slack App"}
          icon={VideoFirstSlackIntegrationIcon}
          width="40"
          link={"/products/slack/"}
          active={true}/>

      </ProductsNav>

      <Container extraClasses={"vf-wallpaper-minimal pv3 pv4-ns tc fade-out"} verticalPadding={false} maxWidth={1200}>

        <h3 className="f3 f2-ns tc normal mt0 mb4">The perfect productivity companion!</h3>
        <div className="f5 f4-ns tc mb4">
          Receive real-time Video First comments without leaving Slack.
        </div>
        <img src={SlackAppCommentsCropped} className={"w-70 mh-auto mb3 mb4-ns"}/>

        {/* <div className="mt4 mb5"><a href="https://slack.com/oauth/authorize?client_id=619326467616.638524608006&scope=channels:read,groups:read,im:read,chat:write:bot,users:read"><img
         alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png"
         srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"/></a></div> */}

        <div className="f5 f4-ns tc mb4">
          See scenario assignments for your team.  Know exactly when scenarios are tagged.
        </div>
        <img src={SlackAppTagsCropped} className={"w-70 mh-auto mb3 mb4-ns"}/>

        <div className="f5 f4-ns tc mb4">
          Never miss an upload you have been asked to review.
        </div>
        <img src={SlackAppReviews} className={"w-70 mh-auto mb3 mb4-ns"}/>
      </Container>

      <Container extraClasses={"tc fade-out"} verticalPadding={false} maxWidth={1200}>
        <h3 className="f3 f2-ns tc normal mt0 mb4">Installation</h3>

        <div className="f5 f4-ns tc mb4">
          <p>Adding slack integration is easy!</p>
          <p>Simply sign up / login into your <a href="https://app.videofirst.io" className="sb">Video First</a> Account, go to the
           <span className="sb"> Projects</span> page and click the <span className="sb">Connect Slack</span> button.
          </p>
        </div>
        <img src={SlackConnectProject} className={"mh-auto mb3 mb4-ns shadow-2"}/>
        <div className="f5 f4-ns tc mb4">
          <p>Once authorized with your Slack workspace, select the <span className="sb">channel / user</span> you wish to
           see your Video First updates.</p>
        </div>
        <img src={SlackConnectChannel} className={"mh-auto mb3 mb4-ns shadow-2"}/>
        <div className="f5 f4-ns tc mb4">
          <p>Any problems, contact us at <a href="mailto:support@videofirst.io" className="sb">support@videofirst.io</a>.
          We will be happy to help!</p>
        </div>
      </Container>

    </main>
  </Layout>
);

export default CapturePage;
